@import 'styling/variables';
@import url('https://fonts.googleapis.com/css2?family=Gotu&family=Source+Sans+Pro:wght@300;400&display=swap');

.default, .roos {
  background-color: $lightBrown;

  .result-block {
    h1, h3 {
      color: $darkBrown;
    }
  }

  .advice-block {
    background-color: $white;
  }
}

.groen{
  background-color: $lightGreen;

  .result-block {
    h1, h3 {
      color: $darkGreen;
    }
  }

  .advice-block {
    background-color: $adviceGreen;
  }
}

.geel{
  background-color: $lightYellow;

  .result-block {
    h1, h3 {
      color: $darkYellow;
    }
  }

  .advice-block {
    background-color: $adviceYellow;
  }
}

.oranje {
  background-color: $lightOrange;

  .result-block {
    h1, h3 {
      color: $darkOrange;
    }
  }

  .advice-block {
    background-color: $adviceOrange;
  }
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  overflow-x: hidden;
  grid-area: main;


  .loading-text {
    align-self: center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 30%;
    font-family: Source Sans Pro, sans-serif;
    margin: 0 auto;
    padding-bottom: 2em;

    p{
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 27px;
    }

    .result-block {
      h1 {
        font-style: normal;
        font-weight: 400;
        font-size: 35px;
        line-height: 45px;
        font-family: Gotu, sans-serif;
      }

      h3 {
        font-style: normal;
        font-weight: 300;
        font-size: 28px;
        line-height: 30px;
        margin: 10px;
      }
    }

    .advice-block {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 24px;
      border-radius: 24px;
      margin-top: 1em;
      margin-bottom: 0.5em;

      p {
        margin-bottom: 0;
      }

      h2 {
        font-family: Gotu, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 1.25em;
        line-height: 28px;
        margin-top: 0;
        margin-bottom: 0;
      }

    }

    .join-us-block {
      text-align: left;
      h2 {
        font-family: Gotu;
        font-style: normal;
        font-weight: 400;
        font-size: 22.6px;
        line-height: 39px;
        text-align: center;
      }

    }

    .website-cta-block {
      margin-top: 2rem;
      h3 {
        font-family: Gotu;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 35px;
      }
    }
  }

  .woman-wrapper {
    position: absolute;
    display: block;
    right: 1em;
    z-index: 0;
    width: 200px;
    overflow-x: hidden;
  }

  &.groen {
    .woman-wrapper {
      width: 300px;
    }
  }

  &.geel {
    .woman-wrapper {
      width: 450px;
    }
  }

  &.oranje {
    .woman-wrapper{
      width: 400px;
    }
  }
}

.footer {
  background-color: $white;
  width: 100vw;
  padding: 0.75em 0;
  text-align: center;
  position: fixed;
  height: 50px;
  bottom: 0;
  grid-area: footer;
}



@media only screen and (max-width: 1024px) {
  .App {
    .content {
      margin: 0 auto;
      width: 90%;
      .result-block {
        align-self: start;
        margin-bottom: 44%;
        text-align: left;
        width: 70%;
      }
    }

    .woman-wrapper {
      max-width: 40%;
    }

    &.roos {
      .woman-wrapper {
        width: 140px;
        right: 0.5em;
      }
    }

    &.groen {
      .woman-wrapper {
        width: 235px;
        max-width: 30%;
        right: 0;
      }
    }

    &.geel {
      .woman-wrapper {
        width: 450px;
      }
    }

    &.oranje {
      .woman-wrapper{
        width: 400px;
        right: 1em;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .App {
    .content {
      .result-block {
        margin-bottom: 1rem;
        width: 50%;
      }
    }

    &.roos {
      .woman-wrapper {
        width: 80px;
        right: 0;
        top: -50px;
      }
    }

    &.groen {
      .woman-wrapper {
        width: 110px;
        right: 0;
        top: 0;
      }
    }

    &.geel {
      .woman-wrapper {
        width: 235px;
        right: -1rem;
      }
    }

    &.oranje {
      .woman-wrapper {
        width: 190px;
        right: -2rem;
        top: -15px;
      }
    }
  }
}



