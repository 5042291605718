@import 'styling/variables';

.btn {
  padding: 16px 30px;
  border-radius: 100px;
  margin: 0 auto;
  color: white;
  text-align: center;
  border: none;

  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
}

.filled {
  &.default,
  &.roos {
    background-color: $darkBrown;
  }

  &.groen {
    background-color: $darkGreen;
  }

  &.geel {
    background-color: $darkYellow;
  }

  &.oranje {
    background-color: $darkOrange;
  }
}

.bordered {
  background-color: unset;
  &.default,
  &.roos {
    color: $darkBrown;
    border: 2px solid $darkBrown;
  }

  &.groen {
    color: $darkGreen;
    border: 2px solid $darkGreen;
  }

  &.geel {
    color: $darkYellow;
    border: 2px solid $darkYellow;
  }

  &.oranje {
    color: $darkOrange;
    border: 2px solid $darkOrange;
  }
}
