$darkBrown: #CB9482;
$lightBrown: #EDE3DF;
$white: #FFF;
$darkGreen: #85B2B2;
$lightGreen: #E0E8DC;
$adviceGreen: #CBD6CA;
$lightYellow: #F0E2C1;
$darkYellow: #D3A136;
$adviceYellow: #FCF9F3;
$lightOrange: #F4E9E1;
$darkOrange: #B77543;
$adviceOrange: #FCFAF8;
